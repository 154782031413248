b, strong {
    font-weight: bold !important;
}
.sidebar-custom {
    background: #fbfbfb;
}
.custom-table tbody tr:first-child td {
    border-top: none !important;
}
.custom-table tbody tr:not(:first-child) td {
    border-top: 1px solid #dba939;
    border-bottom: 1px solid #dba939;
}
.custom-table tfoot td {
    border-top: none !important;
}
.custom-table th {
    border-bottom: none !important;
    font-weight: 700 !important;
    vertical-align: middle !important;
    white-space: nowrap;
}
.custom-table td {
  word-break: break-word;
}
.center-table td,
.center-table th {
    text-align: center !important;
}
.custom-table tr:first-child td {
    border-top: none !important;
}
.custom-table tr:not(:first-child) td {
    border-top: 1px solid #dba939;
    border-bottom: 1px solid #dba939;
}

.file-field .btn {
    display: block !important;
}
.error-message,
.success-message {
    top: 50px !important;
    left: 10px !important;
}
.form-group {
    position: relative;
}




.btn-success {
    border-color: #84c4b7 !important;
    background-color: #84c4b7 !important;
}
.form-check-input[type='checkbox']:checked + label:before,
label.btn input[type='checkbox']:checked + label:before {
    border-color: transparent #0149b3 #0149b3 transparent !important;
}
:host ::ng-deep .badge-success {
    background-color: #84c4b7 !important;
}
@media only screen and (min-width: 600px) {
  .main-page-layout {
    min-height: 100vh;
  }
  .mdb-select-dropdown {
    min-width: fit-content !important;
  }
}

@media only screen and (max-width: 599px) {
  .main-page-layout {
    padding-bottom: 10px;
  }
  .mdb-select-dropdown {
    min-width: 105%;
  }
}

.iti {
  display: block !important;
}
//body.modal-open {
//  overflow: hidden !important;
//}
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import '~quill-mention/dist/quill.mention.min.css';
#mat-quill-counter {
  border: 1px solid #ccc;
  border-width: 0px 1px 1px 1px;
  color: #aaa;
  padding: 5px 15px;
  text-align: right;
}
.btn {
  //text-transform: initial !important;
  padding: 0.5rem 1rem !important;
}
.md-form input {
  box-sizing: inherit !important;
}
.mdb-select-outline .mdb-select-value {
  background-color: white  !important;
}
.md-form input[type='text'] {
  background-color: white !important;
}
button.mdb-autocomplete-clear {
  right: 1.5rem  !important;
  /* top: 5px !important;*/
}
input[type='tel'] {
  border-radius: 50px !important;
}
.switch label {
  font-weight: normal;
}

.eye {
  top: 61px;
  right: 40px;
}
.mdb-select-value-label {
  text-indent: 10px  !important;
}
.mdb-select-placeholder {
  text-indent: 10px  !important;
}
.custom-selectbox {
  width: 100%;
  border: none;
  outline: none;
}
.bc-white {
  background-color: white !important;
}
.addr {
  border: 1px solid black;
  border-radius: 16px;
  padding: 5px;
  margin-bottom: 13px;
  line-height: 2.5rem;
}

.addr:hover {
  background-color: #d89e9e;
  cursor: pointer;
  color: rgb(1, 73, 179);
}
input[type='text']:not(:disabled) {
  background-color: white;
}
.cdk-overlay-container {
  z-index: 1051 !important;
}
