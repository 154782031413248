$success-color: #84c4b7;
$green: #84c4b7;
$warning-color: #e4a700;
$yellow: #e4a700;
$light-yellow: #ffebad;
$info-color: #84c4b7;
$primary-color: #0149b3;
$secondary-color: #d89e9e;
$light-warning-color: #f1d596;

.modal-warning {
    color: $light-warning-color !important;
}
.modal-dialog.modal-notify.modal-warning .modal-header {
    background-color: $warning-color !important;
}
hr {
    boder-color: $info-color;
    color: $info-color;
    background-color: $info-color;
}
.text-primary {
    color: $primary-color !important;
}
.text-pink {
    color: $secondary-color !important;
}
.text-warning {
    color: $warning-color !important;
}
.text-alt {
    color: $info-color !important;
}
.text-light-warning {
    color: $light-warning-color !important;
}
.btn-danger {
    border-color: $secondary-color !important;
    background-color: $secondary-color !important;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.form-control:focus {
    box-shadow: 0 0 0 0.2rem rgb(132 196 183 / 25%) !important;
}
.md-form input[type='text']:focus:not([readonly]),
.md-form input[type='password']:focus:not([readonly]),
.md-form input[type='email']:focus:not([readonly]),
.md-form input[type='url']:focus:not([readonly]),
.md-form input[type='time']:focus:not([readonly]),
.md-form input[type='date']:focus:not([readonly]),
.md-form input[type='datetime-local']:focus:not([readonly]),
.md-form input[type='tel']:focus:not([readonly]),
.md-form input[type='number']:focus:not([readonly]),
.md-form input[type='search-md']:focus:not([readonly]),
.md-form input[type='search']:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]) {
    border-bottom: 1px solid $primary-color !important;
}
.md-form input[type='text']:focus:not([readonly]) {
    border-bottom: none !important;
}
.btn-outline-warning {
    border: 1px solid $warning-color !important;
    color: $warning-color !important;
}
.indigo-text {
    color: $primary-color !important;
}
.range-field .track .range-cloud:after {
    background: $primary-color !important;
}
.range-field input[type='range'] {
    &::-webkit-slider-thumb {
        background: $primary-color !important;
    }
    &::-moz-range-thumb {
        background: $primary-color !important;
    }
    &::-ms-thumb {
        background: $primary-color !important;
    }
}


.mdb-select-outline .mdb-select-value {
  border: 1px solid $secondary-color !important;
  border-radius: 50px !important;
}
.mdb-select-outline .mdb-select-value.focused {
  border: 1px solid $secondary-color !important;
  box-shadow: inset 0 0 0 1px $secondary-color !important;
}
.mdb-select-arrow.focused {
  color: $secondary-color !important;
}
.md-form input[type='text']:focus:not([readonly]),
.md-form input[type='password']:focus:not([readonly]),
.md-form input[type='email']:focus:not([readonly]),
.md-form input[type='url']:focus:not([readonly]),
.md-form input[type='time']:focus:not([readonly]),
.md-form input[type='date']:focus:not([readonly]),
.md-form input[type='datetime-local']:focus:not([readonly]),
.md-form input[type='tel']:focus:not([readonly]),
.md-form input[type='number']:focus:not([readonly]),
.md-form input[type='search-md']:focus:not([readonly]),
.md-form input[type='search']:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]) {
  box-shadow: 0 1px 0 0 $secondary-color  !important;
  border-bottom: 1px solid $secondary-color  !important;
}
//.switch label input[type='checkbox']:checked + .lever:after {
//  background-color: $warning-color;
//  left: 1.5rem;
//}
//.switch label input[type=checkbox]:checked + .lever, .switch label input[type=checkbox]:checked + .lever:after {
//  background-color: $warning-color !important;
//}
//.switch label input[type='checkbox']:checked + .lever {
//  background-color: $light-yellow;
//}
.btn-secondary:not([disabled]):not(.disabled):active {
  background-color: $secondary-color !important;
}
h2.section-title {
  font-size: 32px;
  font-weight: 700;
  color: $primary-color !important;
  padding-bottom: 10px;
}
.dropdown .dropdown-menu .dropdown-item:active, .dropdown .dropdown-menu .dropdown-item:hover, .dropleft .dropdown-menu .dropdown-item:active, .dropleft .dropdown-menu .dropdown-item:hover, .dropright .dropdown-menu .dropdown-item:active, .dropright .dropdown-menu .dropdown-item:hover, .dropup-material .dropdown-menu .dropdown-item:active, .dropup-material .dropdown-menu .dropdown-item:hover {
background-color: $primary-color!important;
}
.btn-secondary {
  background-color: $secondary-color !important;
  color: #fff;
}
.md-form input[type=text] {
  border: 1px solid $secondary-color !important;
  border-radius: 50px !important;
  text-indent: 10px  !important;

}
.btn-secondary:not([disabled]):not(.disabled).active, .btn-secondary:not([disabled]):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
  background-color: $secondary-color !important;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@import '../node_modules/bootstrap/scss/bootstrap';
@import 'app/components/mdb/assets/scss/mdb';
